<template>
  <v-app>
    <AppBar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";
import AppBar from "./components/core/AppBar";
import Footer from "./components/core/Footer";

export default {
  name: "App",

  components: {
    // HelloWorld,
    AppBar,
    Footer,
  },

  data: () => ({
    drawer: true,
  }),
};
</script>

<style>
.elipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #787878;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
::-webkit-scrollbar-thumb:active {
  background: #bababa;
}
::-webkit-scrollbar-track {
  background: #2b2b2b;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #303030;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
@media (orientation: landscape) {
  .aspect-ratio {
    height:56.25vw !important;
  }
}
@media (orientation: portrait) {
  .aspect-ratio {
    height:75vw !important;
  }
}
</style>
