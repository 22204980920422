import Vue from "vue";
import Vuex from "vuex";

import doctors from "./doctors.json";
import procedures from "./procedures.json";
import careers from "./careers.json";
// import wellness from "./wellness.json";
import wellnessPackages from "./wellnessPackages.json";
import clinicalDepartments from "./clinicalDepartments.js";
import clinicalDepartmentsWriteup from "./clinicalDepartmentsWriteup.json";
import directory from "./directory.json";

doctors.sort((a, b) =>
  `${a.lastName}, ${a.firstName}` < `${b.lastName}, ${b.firstName}`
    ? -1
    : `${a.lastName}, ${a.firstName}` > `${b.lastName}, ${b.firstName}`
    ? 1
    : 0
);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiKey: "eSWHugHzUmZQ2GUsBKffyNKeNZHuSWtX",
    apiUrl: "https://apps.uerm.edu.ph:3443/",
    doctors: doctors,
    doctorsFiltered: doctors,
    procedures: procedures,
    directory: directory,
    wellnessPackages,
    clinicalDepartmentsWriteup: clinicalDepartmentsWriteup,
    navs: [
      { href: "/", title: "Home", icon: "fas fa-home" },
      { href: "/about", title: "About Us", icon: "fas fa-question-circle" },
      { href: "/doctors", title: "Our Doctors", icon: "fas fa-user-md" },
      {
        href: "/wellness",
        title: "Wellness Center",
        icon: "fas fa-laptop-medical",
      },
      {
        href: "/clinical-departments",
        title: "Clinical Departments",
        icon: "fas fa-stethoscope",
      },
      { href: "/careers", title: "Careers", icon: "fas fa-users" },
      { href: "/contactus", title: "Contact Us", icon: "fas fa-phone" },
    ],
    careers: careers,
    news: [
      {
        title: `Home Service Guidelines`,
        date: "10-01-2024",
        imageBeforeText: true,
        image1: ["/img/news/home-service-2024.jpg"],
        content: [
          `
          <p style="text-align: center"><strong>HOME SERVICE GUIDELINES</strong></p>
          <p><strong>Who can avail of the services?</strong></p>
          <ol>
            <li>
              Any individual who has diagnostic procedure (included in the offerings)
              requirements may avail of this service;
            </li>
            <li>
              Individuals who are time-starved but require diagnostic and therapeutic
              care;
            </li>
            <li>
              Recovering patients at home (for newly discharged patients) that require
              diagnostic&nbsp;monitoring; and
            </li>
            <li style="text-align: justify">
              Individuals who have difficulty in accessing hospital facility using
              ordinary mode of&nbsp;transportation (e.g., elderly, immune-compromised,
              and bedridden patients).
            </li>
          </ol>
          <p>&nbsp;</p>
          <p><strong>FAQs</strong></p>
          <ol>
            <li>
              <strong
                >What are the services that can be availed through Home Service?</strong
              >
              <ol style="list-style-type: upper-roman">
                <li>
                  <strong>Diagnostic and Therapeutic Services:</strong>
                  <ol style="list-style-type: lower-alpha">
                    <li>
                      Pathology Laboratory Screening Tests
                      <ol style="list-style-type: lower-roman">
                        <li>Hematology</li>
                        <li>Serology/Immunology</li>
                        <li>Hepatitis Profile</li>
                        <li>Blood Chemistry</li>
                        <li>Thyroid Function Test</li>
                        <li>Diabetes Screening Blood Test</li>
                        <li>Kidney Screening Blood Test</li>
                        <li>Drug Analytes</li>
                        <li>&nbsp;Hormones</li>
                        <li>Tumor Markers</li>
                        <li>COVID-19 Test (RT PCR and RAT)</li>
                      </ol>
                    </li>
                    <li>Electrocardiogram (ECG)</li>
                    <li>Prosthetics and Orthotics Assessment and Fitting</li>
                    <li>Nebulization</li>
                  </ol>
                </li>
                <li>
                  <strong>Online Services:</strong>
                  <ol style="list-style-type: lower-alpha">
                    <li>Interpretation of Diagnostic Results (Online Consultation)</li>
                    <li>Prescription of medicines and medical supplies</li>
                    <li>Delivery of prescribed medicines and supplies</li>
                    <li>Nutrition Counselling and Diet Planning</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li style="text-align: justify">
              <strong
                >What are the discounts for Senior Citizen and Person with
                Disability?</strong
              ><br />The senior citizen or person with disability can avail of the
              promotional discount under this&nbsp;special package or the discount
              provided under the Expanded Senior Citizens Act for 2010&nbsp;or Act of
              expanding the benefits and privileges of Disabled Person, whichever is
              higher.&nbsp;Further, the promotional discount or free item(s)/services(s)
              is not convertible to cash,&nbsp;other discount(s) or another item. Should
              the availed procedures be on promotional&nbsp;discount, the promo price
              cannot be used in conjunction with any other offer or discount
              in&nbsp;relation to the specified services.
            </li>
            <li style="text-align: justify">
              <strong>What is the Operating Day / Hours of the Home Service?</strong
              ><br />All Pathology Laboratory procedures (blood works) can be requested
              from Monday to&nbsp;Sunday, 5:00 AM to 10:00 PM. Other diagnostic and
              therapeutic services may be scheduled&nbsp;from Monday to Sunday, 8:00 AM
              to 5:00 PM. Requested appointment that falls during a&nbsp;holiday will be
              rescheduled on the next available operating day.
            </li>
            <li style="text-align: justify">
              <strong>Can I use my HMO or company guarantee for this service?</strong
              ><br />Yes, you can avail of your HMO or company guarantee.&nbsp;<br /><br />You
              will be redirected to the HMO Concierge for the application of your HMO
              benefits. Prior&nbsp;to the confirmation of appointment schedule, an
              approved Letter of Guaranty shall be made&nbsp;available to ensure all
              procedures requested are covered by the health card.
            </li>
            <li><strong>How much is the home service transportation fee?</strong></li>
          </ol>
          <table style="border-collapse: collapse; width: 100%" border="1">
            <colgroup>
              <col style="width: 33.3049%" />
              <col style="width: 33.3049%" />
              <col style="width: 33.3049%" />
            </colgroup>
            <tbody>
              <tr>
                <td style="text-align: center"><strong>LOCATION</strong></td>
                <td style="text-align: center">
                  <strong>TRANSPORTATION </strong><strong>FEE *</strong>
                </td>
                <td style="text-align: center">
                  <strong>MINIMUM BILL REQUIREMENT</strong><br />(Net of Discount)
                </td>
              </tr>
              <tr>
                <td style="text-align: left">&nbsp;&nbsp;Within Quezon City</td>
                <td style="text-align: center">FREE of charge</td>
                <td style="text-align: center">NO minimum requirement</td>
              </tr>
              <tr>
                <td style="text-align: left" rowspan="2">&nbsp;&nbsp;Within Metro Manila</td>
                <td style="text-align: center">FREE of charge</td>
                <td style="text-align: center">₱ 1,000 or above</td>
              </tr>
              <tr>
                <td style="text-align: center">₱ 400</td>
                <td style="text-align: center">If total bill is below ₱ 1,000</td>
              </tr>
            </tbody>
          </table>
          <ol start="6">
            <li  style="text-align: justify">
              <strong>How will my samples be collected?</strong><br />The UERM
              Healthcare Provider will go to the confirmed address for specimen
              collection, or procedure venue (as specified in your appointment schedule). We ensure that the quality of care provided is the same as the
              service you receive during your next visit in the hospital.
            </li>
            <li>
              <strong>How will I receive my laboratory results?</strong><br />Results
              can be viewed through the following:
              <ul>
                <li>E-mailed to your registered email address</li>
                <li>
                  Online Patient Portal
                  <a href="https://uermhospital.com.ph/apps/patient-portal/#/login"  target="_blank"
                    >https://uermhospital.com.ph/apps/patient-portal/#/login</a
                  >.
                </li>
                <li>
                  If you prefer to receive a printed copy, this can be provided during
                  your visit at UERM Medical Center.
                </li>
              </ul>
            </li>
          </ol>
          <br>
          <br>
          <p style="text-align: center"><strong>APPOINTMENT PROCESS</strong></p>
          <ol>
            <li>
              Individuals can schedule appointments through the following:
              <ol style="list-style-type: upper-alpha">
                <li>
                  Call our Patient Navigator at (02) 8715 0861 to 77 local 509 or 514;
                  or
                </li>
                <li>Viber call or send a Viber message at 0998 5883795; or</li>
                <li>Message us at @UERMOffical Facebook (FB) Messenger; or</li>
                <li>
                  Fill up the online form:<br />
                    <a href="https://docs.google.com/forms/d/192bqYC67qHBO8x-4ZYK5UPZ6FS2Hlafxn287-gz7qzY/viewform?edit_requested=true#settings" target="_blank">
                      https://docs.google.com/forms/d/192bqYC67qHBO8x-4ZYK5UPZ6FS2Hlafxn287-gz7qzY/viewform?edit_requested=true#settings
                    </a>
                </li>
              </ol>
            </li>
            <li style="text-align: justify">
              UERM&rsquo;s Patient Navigator will contact you to confirm the details of
              your request and will&nbsp;provide estimated rates for all the services
              that will be availed.
            </li>
            <li style="text-align: justify">
              Payment for the services availed can be done thru Bank Transfer, Credit
              Cards (Visa and&nbsp;Mastercard), GCash and Pay Maya. Send your proof of
              payment through Viber at 09985883795 or by sending it at
              <a href="mailto:homeservice@uerm.edu.ph">homeservice@uerm.edu.ph</a>
            </li>
            <li style="text-align: justify">
              After payment, confirmation of appointment details, including date, time,
              specific&nbsp;preparation for the services (i.e. fasting requirements,
              medication adjustments, or any&nbsp;preparatory steps) and total cost of
              services will be sent thru the patients&rsquo; provided email&nbsp;address
              or Viber number or FB Messenger account.
            </li>
          </ol>`,
        ],
      },
      {
        title: `Full Mechanics for Cataract Packages`,
        date: "09-26-2024",
        imageBeforeText: true,
        image1: ["/img/news/cataract-packages-sept-2024.png"],
        content: [
          `<p style="text-align: center;"><strong><span style="text-decoration: underline;">CATARACT EXTRACTION PACKAGES</span></strong></p>
          <p style="text-align: center;">QUALIFICATION AND PACKAGE MECHANICS</p>
          <ol>
            <li style="text-align: justify;">The Cataract Packages (Phacoemulsification or Extracapsular Cataract Extraction for one (1) eye) include the procedure fee, supplies and medicines, doctors&rsquo; professional fees on surgery and post-surgery consultation with an Ophthalmology Physician. The package does not include intraocular lens implant and will be separately charged to patient.</li>
            <li style="text-align: justify;">The package is available for all individual patients ages 18 years old and above.</li>
            <li style="text-align: justify;">Patients with other pre-existing eye condition (e.g., retinal detachment, strabismus, etc.) are not qualified to avail the package.</li>
            <li style="text-align: justify;">The senior citizen or person with disability can avail of the promotional discount under this special package or the discount provided under the Expanded Senior Citizens Act for 2010 or Act of expanding the benefits and privileges of Disabled Person, whichever is higher. This promo cannot be used in conjunction with any other offer or discount in relation to the specified services. Further, the promotional discount or free item(s)/services(s) is not convertible to cash, other discount(s) or other item(s).</li>
            <li style="text-align: justify;">Any additional supplies or medicines in excess of the items included in the package will be separately charged to the patient with 10% discount.</li>
            <li style="text-align: justify;">Patients availing the package rate after PhilHealth must meet all the criteria and requirement of PhilHealth's benefit eligibility requirements. In case of disapproval, the individual charges will be applied.</li>
            <li style="text-align: justify;">Promo is available for Cash, Credit Cards (Visa and Mastercard), cheque payments, GCash&nbsp;transactions only.</li>
            <li style="text-align: justify;">To make an appointment, please consult with your Ophthalmologist or call our Patient Navigator at&nbsp;8715-0861 to 77 local 509 or 514 or via Viber or WhatsApp at 0998 5883795.</li>
          </ol>`,
        ],
      },
      {
        title: `Full Mechanics for Lung Care Package`,
        date: "08-15-2024",
        imageBeforeText: true,
        image1: ["/img/news/uerm-lung-care-center-august-2024.jpg"],
        content: [
          `<p style="text-align: center">
            <strong
              ><span style="text-decoration: underline"
                >SPECIAL PACKAGE FOR NATIONAL LUNG MONTH</span
              ></strong>
          </p>
          <p style="text-align: center">QUALIFICATION AND PROMO MECHANICS</p>
          <ol>
            <li style="text-align: justify">
              The Lung Care Package includes consultation with a Pulmonologist,
              Pulmonary Function Test and Chest X-Ray services.
            </li>
            <li style="text-align: justify">
              The package is available for all individual patients ages 18 years old
              and above.
            </li>
            <li style="text-align: justify">
              This offer is valid from August 12 to 31, 2024 only.
            </li>
            <li style="text-align: justify">
              This promo cannot be used in conjunction with any other offer or
              discount in relation to the specified services. However, the senior
              citizen or person with disability can avail of the promotional discount
              under this special package or the discount provided under the Expanded
              Senior Citizens Act for 2010 or Act of expanding the benefits and
              privileges of Disabled Person, whichever is higher. Further, the
              promotional discount or free item(s)/services(s) is not convertible to
              cash, other&nbsp;discount(s) or other item(s).
            </li>
            <li style="text-align: justify">
              Any additional pulmonary screening procedures on the same day of
              availment will be charged&nbsp;separately to the patient with 20%
              discount.
            </li>
            <li style="text-align: justify">
              Promo is available for Cash, Credit Cards (Visa and Mastercard), cheque
              payments, GCash&nbsp;transactions only.
            </li>
            <li style="text-align: justify">
              To avail of the special package, please present the screenshot of the
              social media post or picture of&nbsp;the poster to the Patient Navigator
              at the Hospital Lobby or to the Pulmonary
              Laboratory&rsquo;s&nbsp;Representative at 2nd Floor, Pay Hospital
              Building
            </li>
            <li style="text-align: justify">
              To make an appointment, call our Patient Navigator at 8715-0861 to 77
              local 509 or 514 or reach us&nbsp;via viber at 0998 5883795 or through
              our official Facebook page at UERMOfficial.
            </li>
          </ol>`,
        ],
      },
      {
        title: `Full Mechanics for Eye Screening Packages`,
        date: "08-07-2024",
        imageBeforeText: true,
        image1: ["/img/news/sight-saving-month-2024.jpg"],
        content: [
          `<p style="text-align: center;"><span style="text-decoration: underline;"><strong>SPECIAL PACKAGE FOR SIGHT SAVING MONTH</strong></span></p>
          <p style="text-align: center;"><br>QUALIFICATION AND PROMO MECHANICS</p>
          <ol>
              <li>The Eye Screening Packages include the consultation with an Ophthalmology Physician, Eye Health Screening services.</li>
              <li>The package is available for all individual patients ages 18 years old and above.</li>
              <li>This offer is valid from August 1 to 31, 2024 only.</li>
              <li>This promo cannot be used in conjunction with any other offer or discount in relation to the specified services. However, the senior citizen or person with disability can avail of the promotional discount under this special package or the discount provided under the Expanded Senior</li>
              <li>Citizens Act for 2010 or Act of expanding the benefits and privileges of Disabled Person, whichever is higher. Further, the promotional discount or free item(s)/services(s) is not convertible to cash, other discount(s) or other item(s).</li>
              <li>Patients availing the ₱1,099 cash-out promo for Retina Screening promo must meet all the criteria and requirement of Philhealth's benefit eligibility requirements. In case of disapproval, the package rate of ₱4,999 will be applied.</li>
              <li>Any additional eye screening procedures on the same day of availment will be charged separately to the patient with 20% discount.</li>
              <li>Promo is available for Cash, Credit Cards (Visa and Mastercard), cheque payments, GCash transactions only.</li>
              <li>To avail of the special package, please present the screenshot of the social media post or picture of the poster to the Patient Navigator at the</li>
              <li>Hospital Lobby or to the Eye Center's Representative at 2nd Floor, Diagnostic Wing, Pay Hospital Building<br>To make an appointment, call our Patient Navigator at 8715-0861 to 77 local 509 or 514 or reach us&nbsp;at 0998 5883795.</li>
          </ol>`,
        ],
      },
      {
        title: `Full Mechanics for “Best You with UERM” Photo Liking Challenge`,
        date: "07-22-2024",
        imageBeforeText: true,
        image1: ["/img/news/best-you-uerm-photo-liking-contest.jpg"],
        content: [
          `<p style="text-align: center;"><span style="text-decoration: underline;"><strong>WHO ARE QUALIFIED TO JOIN</strong></span></p>
          <ol>
          <li>The &ldquo;Best You with&nbsp;UERM&rdquo; Photo Liking Challenge is open to all individuals age eighteen (18)&nbsp;years old and above.</li>
          <li>Home address must be within Metro Manila only.</li>
          <li>Employees of the Medical Center, their affiliates, sponsors, organizers, advertising agencies,&nbsp;and judges, including their relatives up to third degree of consanguinity or affinity, are not&nbsp;eligible to join this competition.</li>
          </ol>
          <p style="text-align: center;">&nbsp;</p>
          <p style="text-align: center;"><span style="text-decoration: underline;"><strong>CONTEST MECHANICS AND GUIDELINES</strong></span></p>
          <p><strong>REGISTRATION AND SUBMISSION OF ENTRIES</strong></p>
          <ol>
          <li style="text-align: justify;">Registration and entry submission period is from 12:00 AM of July 22, 2024 to 11:59 PM of&nbsp;July 31, 2024. Contestants must fully accomplish the registration requirements before&nbsp;sending their entries.</li>
          <li style="text-align: justify;">To register, the contestants must follow the registration process:&nbsp;
          <ol style="list-style-type: lower-alpha;">
          <li>Fill out personal information through the following Google Form link:<br><a href="https://docs.google.com/forms/d/1GVaP_7zLCTncLh6T7t6iQ_orxbqfBUslYLCDsrwIZrU/" target="_blank" rel="noopener">https://docs.google.com/forms/d/1GVaP_7zLCTncLh6T7t6iQ_orxbqfBUslYLCDsrwIZrU/edit</a></li>
          <li>Interested participant must like and follow the UERMOfficial Facebook (FB) page<br><a href="https://www.facebook.com/UERMOfficial/" target="_blank" rel="noopener">https://www.facebook.com/UERMOfficial/</a></li>
          </ol>
          </li>
          <li style="text-align: justify;">The participating contestant may only register one account per one unique Facebook profile or page (user). Only registered entries will be accepted as contest participant.</li>
          <li>To submit their entry, participants must follow this process:
          <ol style="list-style-type: lower-alpha;">
          <li style="text-align: justify;">Participants may submit their entry within the contest period: from 12:00 AM of July 22, 2024 to 11:59 PM of July 31, 2024.</li>
          <li style="text-align: justify;">Contestant&rsquo;s Facebook account and the selfie picture must bet set for public viewing.</li>
          <li style="text-align: justify;">Participant must upload their selfie picture in their own Facebook account as&nbsp;specified in the registration form.</li>
          <li style="text-align: justify;">Each contestant may submit only one (1) unique entry. Posted pictures cannot be re-submitted by another user to avoid duplication. For entries with duplicates, the first&nbsp;one submitted will only be considered for the contest.</li>
          <li style="text-align: justify;">Each entry must contain a selfie picture with your favorite healthful meal/dish.</li>
          <li style="text-align: justify;">Include in the caption the following information:
          <ol>
          <li>Recipe of your healthful dish/meal</li>
          <li>Answer to the following: How can your nutritious dish/meal help you to become the best you?</li>
          <li>Activities&rsquo; Hashtags: #BestYouwithUERM #UERMNutritionMonth #UERM</li>
          </ol>
          </li>
          <li style="text-align: justify;">Automatic disqualification for those entries that have:
          <ol>
          <li>Image that are not consistent with the vision, mission, and core values of the Medical Center;</li>
          <li>Copyrighted material/s to include artworks and other photos; and</li>
          <li>Date stamps, watermarks or any text on the image.</li>
          </ol>
          </li>
          <li style="text-align: justify;">Photo entry must only be an entrant in this contest and must not have been recipient&nbsp;of awards in other contests and or published in any media to include not limited to&nbsp;news, advertising, publications and other similar means</li>
          </ol>
          </li>
          </ol>
          <p>&nbsp;</p>
          <p><strong>GUIDELINES AND CRITERIA FOR JUDGING</strong></p>
          <ol>
          <li style="text-align: left;">Among the participants, the panel of judges will choose the Top 3 winners on August 5, 2024&nbsp;(Friday) with the following criteria:
          <div style="margin: 10px;">
          <table style="border-collapse: collapse; width: 100%; height: 144.688px; border-width: 1px; border-spacing: 10px;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>
          <tbody>
          <tr style="height: 36.1719px;">
          <td>&nbsp;Number of Reactions on the Picture (i.e. Likes, Hearts, and&nbsp;Wow)</td>
          <td style="text-align: center;">50%</td>
          </tr>
          <tr style="height: 36.1719px;">
          <td>&nbsp;Content and Relevance of the Caption</td>
          <td style="text-align: center;">40%</td>
          </tr>
          <tr style="height: 36.1719px;">
          <td>&nbsp;Compliance to all contest rules and guidelines</td>
          <td style="text-align: center;">10%</td>
          </tr>
          <tr style="height: 36.1719px;">
          <td><strong>&nbsp;TOTAL</strong></td>
          <td style="text-align: center;"><strong>100%</strong></td>
          </tr>
          </tbody>
          </table>
          </div>
          </li>
          <li style="text-align: justify;">The UERM Panel of Judges will be screening and judging the entries based on the Judging&nbsp;and Selection process. If, for any purpose, the brand deems the submission to be unqualified&nbsp;or falls under other reasons,&nbsp;UERM reserves the right to disqualify the entry.</li>
          <li style="text-align: justify;">The contestant is responsible for their own production expenses and logistic arrangements.&nbsp;Neither&nbsp;UERM Medical Center nor any of those involved in any capacity in organizing,&nbsp;executing, judging or supplying prizes for this contest is responsible for covering or&nbsp;reimbursing any expense that maybe incurred by the contestants.</li>
          <li style="text-align: justify;">The contestants and the winners agree to the use of their photograph/s, name, personal&nbsp;biography, submitted photo or material/s within the current calendar year and in the&nbsp;succeeding two (2) years, and the use of that information in all forms of media and&nbsp;communication related to UERM and UERM Nutrition Month&rsquo;s activities without&nbsp;compensation, consideration or notification and hereby releases UERM Medical Center, its&nbsp;employees, representatives, and organizers, from any claims or liability arising out of or with&nbsp;respect to such use and , in particular, irrevocably assign any and all copyright right to UERM&nbsp;Medical Center regarding the contestant&rsquo;s submitted entry/material in the &ldquo;The Best You&nbsp;with UERM&rdquo; Photo Liking Contest and the contestant and his/her parents or guardians&nbsp;waives any and all moral rights relating to same.</li>
          <li style="text-align: justify;">The general rules and regulations of the &ldquo;The Best You with UERM&rdquo; Photo Liking Contest do&nbsp;not impose any obligation on UERM Medical Center or any other individual, company, or&nbsp;organization to post or repost and broadcast or rebroadcast entries submitted by&nbsp;contestants or imposes liability on UERM Medical Center or any other individual company or&nbsp;organization for failure to do so.</li>
          <li style="text-align: justify;">While recognizing that the judges, corporate sponsors, parents/guardians and contestants&nbsp;may know each other outside of this competition, UERM Medical Center asks that no contact&nbsp;or communication intended to influence, or that would tend to influence, a judge&rsquo;s decision&nbsp;about the contestant&rsquo;s entry shall occur between contestants and &ldquo;The Best You with&nbsp;UERM&rdquo;&nbsp;Photo Liking Contests&rsquo; judges or corporate sponsors.</li>
          </ol>`,
        ],
      },
      {
        title: `Full Mechanics for "We Got Your Children’s Back" Package`,
        date: "07-09-2024",
        imageBeforeText: true,
        image1: ["/img/news/we-got-your-childrens-back.jpg"],
        content: [
          `<p style="text-align: center;"><span style="text-decoration: underline;"><strong>SPECIAL PACKAGE FOR NATIONAL DISABILITY REHABILITATION & PREVENTION (NDRP) WEEK</strong></span></p>
        <p style="text-align: center;">QUALIFICATION AND PROMO MECHANICS</p>
        <ol>
        <li style="text-align: justify;">The "We Got Your Children&rsquo;s Back" Package includes the consultation with a Physical Rehabilitation Physician,&nbsp;Comprehensive Assessment of a Certified Prosthetist and Orthotist, Spine X-Ray, Customized Assistive Device&nbsp;(Thoracolumbosacral Orthosis) and one-time Physical Therapy.</li>
        <li style="text-align: justify;">The package is available for all pediatric patients ages 17 years old and below.</li>
        <li style="text-align: justify;">This offer is valid from July 9 to 31, 2024 only.</li>
        <li style="text-align: justify;">This promo cannot be used in conjunction with any other offer or discount in relation to the specified services.&nbsp;However, the senior citizen or person with disability can avail of the promotional discount under this special&nbsp;package or the discount provided under the Expanded Senior Citizens Act for 2010 or Act of expanding the&nbsp;benefits and privileges of Disabled Person, whichever is higher. Further, the promotional discount or free&nbsp;item(s)/services(s) is not convertible to cash, other discount(s) or other item(s).</li>
        <li style="text-align: justify;">Any additional procedures or upgrade in the devices will be charged separately to the patient with 20% discount.</li>
        <li style="text-align: justify;">Only patients who have pre-authorization approval from Philhealth 30 days upon availment of the package&nbsp;will be qualified to avail the Plan B package. Patients availing the Plan B with cash-out promo of ₱20,000.00&nbsp;will only be applicable to patients with approved availment for Philhealth&rsquo;s Z Benefit. In case of disapproval,&nbsp;Plan A package rate will be applied.</li>
        <li style="text-align: justify;">Assistive device comes with (1) month warranty on parts and services (Check warranty details prior to&nbsp;availment of the package). This also includes a one-time free adjustment upon delivery.</li>
        <li style="text-align: justify;">Promo is available for Cash, Credit Cards (Visa and Mastercard), cheque payments, GCash transactions only.</li>
        <li style="text-align: justify;">To avail of the special package, please present the screenshot of the social media post or picture of the poster&nbsp;to the Patient Navigator at the hospital lobby.</li>
        <li style="text-align: justify;">To make an appointment, call our Patient Navigator at 8715-0861 to 77 local 509 or 514 or reach us at 09985883795.</li>
        </ol>`,
        ],
      },
      {
        title: `Full Mechanics for "We Can Hear You" Package`,
        date: "07-09-2024",
        imageBeforeText: true,
        image1: ["/img/news/we-hear-you.jpg"],
        content: [
          `<p style="text-align: center;"><span style="text-decoration: underline;"><strong>SPECIAL PACKAGE FOR NATIONAL DISABILITY REHABILITATION &amp; PREVENTION (NDRP) WEEK</strong></span></p>
          <p style="text-align: center;">QUALIFICATION AND PROMO MECHANICS</p>
          <ol>
          <li style="text-align: justify;">The "We Can Hear You" Package includes the consultation with an&nbsp;ENT Physician, Hearing Test (i.e.&nbsp;Pure Tone Audiometry, Speech Assessment, and Tympanometry), and Customized Hearing Aid&nbsp;Device (N2 BTE or RIC Type).</li>
          <li style="text-align: justify;">This offer is valid from July 9 to September 30, 2024 only.</li>
          <li style="text-align: justify;">The promo cannot be used in conjunction with any other offer or discount in relation to the specified services. However, the senior citizen or person with disability can avail of the promotional discount under this special package or the discount provided under the Expanded Senior Citizens Act for 2010&nbsp;or Act of expanding the benefits and privileges of Disabled Person, whichever is higher. Further, the&nbsp;promotional discount or free item(s)/services(s) is not convertible to cash, other discount(s) or other&nbsp;item(s).</li>
          <li style="text-align: justify;">Any additional procedures or upgrade in the devices will be charged separately to the patient with&nbsp;20% discount.</li>
          <li style="text-align: justify;">Patients availing lay-away plan must pay the package rate in full prior to delivery of the device.</li>
          <li style="text-align: justify;">All hearing aids have one (1) month warranty upon delivery on parts and services, except battery.&nbsp;Please check warranty details prior to availment of the package.</li>
          <li style="text-align: justify;">Promo is available for Cash, Credit Cards (Visa and Mastercard), cheque payments, GCash and in lay-away plan (up to 6 months) only.</li>
          <li style="text-align: justify;">To avail of the special package, please present the screenshot of the social media post or picture of&nbsp;the poster to the Patient Navigator at the hospital lobby.</li>
          <li style="text-align: justify;">To make an appointment, call our Patient Navigator at 8715-0861 to 77 local 509 or 514 or reach us at 09985883795.</li>
          </ol>`,
        ],
      },
      {
        title: "Full Mechanics for Weight and Nutrition Check Package",
        date: "07-09-2024",
        image1: ["/img/news/weight-and-nutrition.jpg"],
        imageBeforeText: true,
        content: [
          `<p style="text-align: center;"><span style="text-decoration: underline;"><strong>SPECIAL PACKAGE FOR NATIONAL NUTRITION MONTH</strong></span></p>
          <p style="text-align: center;">QUALIFICATION AND PROMO MECHANICS</p>
          <ol>
          <li style="text-align: justify;">The "Weight and Nutrition Check" Package includes consultation with a Clinical Nutrition Physician, Nutrition Counseling and Diet Plan, and diagnostic procedures (Body Composition&nbsp;Analyzer, ECG, Lipid Profile, Electrolytes and HBA1C).</li>
          <li style="text-align: justify;">This offer is valid from July 6 to 31, 2024 only.</li>
          <li style="text-align: justify;">This promo cannot be used in conjunction with any other offer or discount in relation to the specified&nbsp;services. However, the senior citizen or person with disability can avail of the promotional discount&nbsp;under this special package or the discount provided under the Expanded Senior Citizens Act for 2010&nbsp;or Act of expanding the benefits and privileges of Disabled Person, whichever is higher. Further, the&nbsp;promotional discount or free item(s)/services(s) is not convertible to cash, other discount(s) or other&nbsp;item(s).</li>
          <li style="text-align: justify;">Any additional procedures or nutritional product availment will be charged separately with 20%&nbsp;discount.</li>
          <li style="text-align: justify;">Promo is available for Cash, Credit Cards (Visa and Mastercard), cheque payments, and GCash transactions only.</li>
          <li style="text-align: justify;">To avail of the special package, please present the screenshot of the social media post or picture of&nbsp;the poster to the Patient Navigator at the hospital lobby.</li>
          <li style="text-align: justify;">To make an appointment, call our Patient Navigator at 8715-0861 to 77 local 509 or 514 or reach us&nbsp;at 0998 5883795.</li>
          </ol>
        `,
        ],
      },
      {
        title: "Promotion of TB-Related Services",
        date: "04-17-2024",
        image1: ["/img/news/tb-related-services.png"],
        content: [
          `<p>Tuberculosis can be treated and avoided. The UERM Medical Center offers a wide array of diagnostic and therapeutic services for the prevention and treatment of TB, including GeneXpert test.</p>
        <p> For appointments, kindly reach out to our Information Section at (+632) 8713-33-03 to connect with our doctors, or dial (+632) 8715-0861 to 77 Local 337 to explore your treatment choices.</p>
        <p><strong>#UERM #ExceptionalPeopleExtraordinaryCare #April2024</strong></p>
        `,
        ],
      },
      {
        title:
          "March 2024 Philippine Pediatric Society Specialty Board Written Exam for Diplomates",
        date: "04-11-2024",
        image1: ["/img/news/JEDDIAH T BOLIVAR MD.jpg"],
        content: [
          "March 2024 Philippine Pediatric Society Specialty Board Written Exam for Diplomates",
        ],
      },
    ],
    heritage: [
      "On January 10, 1960, the UERM Medical Center was inaugurated with state-of-the-art healthcare facilities. It serves as the clinical laboratory for the Colleges of Medicine and Nursing and subsequently the College of Physical Therapy.",
      "The Hospital has earned the same high reputation and prestige that UERMMMC medical professors and UERMMMC College of Medicine graduates have earned in the field of medical practice and in the government medical board examinations.",
      "The UERMMMC Hospital is where, being a Teaching Hospital, UERMMMC students gain experience through direct contact with patients; and it is where, as a Pay Hospital, the College of Medicine professors treat their own patients.",
      "Since inception, the Hospital has been providing a wide-range of facilities and specialties in: Surgery, Ob-Gynecology, Pediatrics, Ophthalmology, Otorhinolaryngology, Neurology, Psychiatry, Dermatology, Gastroenterology, Endocrinology, Nephrology, Physical Medicine and Rehabilitation, Cardiology, Pulmonology, Diabetology, and Urology.",
    ],
    milestones: [
      {
        date: "2010",
        content: ["Inaugurated the new Gastrointestinal - Liver Study Unit."],
      },
      {
        date: "2016",
        content: [
          "Launched of the Health Maintenance Organization (HMO) Concierge which coincided with the 59th Foundation Day celebration of the UERMMMCI.",
          "Launched of the Health and Wellness Center.",
          "The UERM Medical Center was one of the Recipients to the Quezon City Manuel L. Quezon Gawad Parangal as the Most Outstanding Institution for 2016.",
        ],
      },
      {
        date: "2017",
        content: [
          "Inaugurated the newly-renovated Pay Hospital patients’ rooms.",
          "Establishment of the new Doctors' Clinics at the 2nd and 3rd floors of the Medical Arts Building.",
          "Establishment of the new Neurology Office and Ward, and Psychiatry Ward at the Upper Ground Floor of the College of Medicine Building.",
        ],
      },
      {
        date: "2019",
        content: [
          "Inaugurated the newly-renovated Diagnostic Centers and ground floor of Pay Hospital.",
        ],
      },
      {
        date: "2020",
        content: [
          "Launched of Mental Healthcare for COVID-19 Frontliners.",
          "UERM Hospital 60th Foundation Day.",
          "Blessing of the new Emergency Room.",
        ],
      },
    ],
    clinicalDepartments: clinicalDepartments,
  },
  mutations: {
    filterDoctors(state, search) {
      if (search == "") {
        state.doctorsFiltered = state.doctors
          .filter((d) => d.status == "ACTIVE")
          .filter((doctor, key) => key < 50);
        return;
      }
      const regexSearch = new RegExp(search, "ig");
      state.doctorsFiltered = state.doctors
        .filter((d) => d.status == "ACTIVE")
        .filter(
          (doctor) =>
            doctor.lastName.match(regexSearch) ||
            doctor.firstName.match(regexSearch) ||
            (doctor.specialty != null && doctor.specialty.match(regexSearch)) ||
            (doctor.alias != null && doctor.alias.match(regexSearch)) ||
            (doctor.clinicalDepartment != null &&
              doctor.clinicalDepartment.match(regexSearch)) ||
            (doctor.hmoShort != null && doctor.hmoShort.match(regexSearch)) ||
            (doctor.hmoShort != null && doctor.hmoShort.match(regexSearch)) ||
            (doctor.hmoShort != null && doctor.hmoShort.match(regexSearch)) ||
            (doctor.hmo != null && doctor.hmo.match(regexSearch))
        );
    },
    populateDoctors(state, doctors) {
      state.doctors = doctors;
      state.doctorsFiltered = doctors
        .filter((d) => d.status == "ACTIVE")
        .filter((doctor, key) => key < 50);
    },
  },
  actions: {
    filterDoctors(state, search) {
      state.commit("filterDoctors", search.trim());
    },
    async populateDoctors(state) {
      const url = `${this.state.apiUrl}doctors?auth=${this.state.apiKey}`;
      const response = await fetch(url, { method: "GET" }).then((response) =>
        response.json()
      );
      state.commit("populateDoctors", response.doctors);
    },
  },
  modules: {},
  getters: {
    doctors(state) {
      return state.doctorsFiltered.filter((d) => d.status == "ACTIVE");
    },
    careers(state) {
      return state.careers;
    },
    navs(state) {
      return state.navs;
    },
    news(state) {
      return state.news.filter((i, key) => key < 6);
    },
    heritage(state) {
      return state.heritage;
    },
    milestones(state) {
      return state.milestones.reverse();
    },
    clinicalDepartmentsList(state) {
      return state.clinicalDepartments.map((dept) => {
        return { title: dept.title, subtitle: dept.subtitle };
      });
    },
    clinicalDepartmentDetail(state, department) {
      return state.clinicalDepartments.filter(
        (dept) => dept.title == department
      );
    },
    hmos(state) {
      return state.hmos;
    },
    departmentProcedures(state) {
      return state.procedures;
    },
    directory(state) {
      return state.directory;
    },
    wellnessPackages(state) {
      return state.wellnessPackages;
    },
  },
});
