import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import VueAnalytics from 'vue-analytics'
import VueFbCustomerChat from "vue-fb-customer-chat";

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: "UA-175236489-1",
  router,
});
Vue.use(VueFbCustomerChat, {
  page_id: 105562784751543, //  change 'null' to your Facebook Page ID,
  theme_color: "#1a72c9", // theme color in HEX
  locale: "en_US", // default 'en_US'
});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
