<template>
  <div>
    <v-navigation-drawer right temporary app v-model="drawer">
      <v-container class="text-center">
        <v-img
          width="40%"
          class="ma-auto"
          src="img/logos/logo-alt-01.png"
        ></v-img>
      </v-container>
      <div class="ma-3">
        <div class="my-1" v-for="(nav, key) in navs" :key="key">
          <v-btn
            class="primary--text"
            small
            text
            v-if="nav.children == null"
            :to="nav.href"
          >
            {{ nav.title }}
            <v-icon right small>{{ nav.icon }}</v-icon>
          </v-btn>

          <v-menu v-if="nav.children != null" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="primary--text" small text v-bind="attrs" v-on="on">
                {{ nav.title }}
                <v-icon right small>fas fa-caret-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in nav.children" :key="index">
                <v-list-item-title>
                  <v-btn download v-if="item.isFile" class="primary--text" small text :href="item.href">
                    {{ item.title }}
                    <v-icon right small>{{ item.icon }}</v-icon>
                  </v-btn>
                  <v-btn v-else class="primary--text" small text :to="item.href">
                    {{ item.title }}
                    <v-icon right small>{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-menu v-if="nav.children != null" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="nav.children != null" v-on="on" v-bind="attrs" small text outlined>{{ nav.title }}</v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(child, key) in nav.children" :key="key">
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>-->
        </div>
        <!-- <div v-for="(nav, key) in navs" class="mb-3" v-bind:key="key">
          <v-btn
            class="primary--text"
            v-if="nav.href.substring(0,1) == 'h'"
            target="_blank"
            :href="nav.href"
            small
            text
          >
            <v-icon class="mr-3">{{nav.icon}}</v-icon>
            {{nav.title}}
          </v-btn>
          <v-btn
            class="primary--text"
            v-if="nav.href.substring(0,1) != 'h'"
            :to="nav.href"
            small
            text
          >
            <v-icon class="mr-3">{{nav.icon}}</v-icon>
            {{nav.title}}
          </v-btn>
        </div>-->
      </div>
    </v-navigation-drawer>
    <v-app-bar app color="white" elevate-on-scroll>
      <div class="d-flex align-center">
        <router-link to="/" class="text-decoration-none d-flex align-center">
          <v-img
            alt="Logo"
            class="shrink mr-2"
            contain
            src="img/logos/logo-alt-01.png"
            transition="scale-transition"
            width="80"
          />
          <span style="line-height:1.2rem;font-size:1rem;"
            >Exceptional People, Extraordinary Care</span
          >
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon v-on:click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      navs: [
        { href: "/", title: "Home", icon: "fas fa-home" },
        { href: "/about", title: "About Us", icon: "fas fa-question-circle" },
        {
          title: "Our Doctors",
          children: [
            {
              href: "/doctors",
              title: "Meet our doctors",
              icon: "fas fa-user-md",
            },
            {
              href: "/clinical-departments",
              title: "Clinical Departments",
              icon: "fas fa-stethoscope",
            },
          ],
        },
        {
          title: "Our Services",
          children: [
            {
              href: "/online-services",
              title: "Online Services",
              icon: "fas fa-laptop-medical",
            },
            {
              href: "/wellness",
              title: "Wellness Center",
              icon: "fas fa-laptop-medical",
            },
            {
              href: "/diagnostic-centers/Radiology X-Ray Section",
              title: "Diagnostic Centers",
              icon: "fas fa-stethoscope",
            },
          ],
        },
        {
          title: "Our Community",
          children: [
            {
              isFile: true,
              href: "/pdf/Annual Report 2020.pdf",
              title: "Annual Report",
              icon: "fas fa-book",
            },
            {
              href: "/uerm-community",
              title: "UERM Community",
              icon: "fas fa-book",
            },
          ],
        },
        { href: "/patient-guide", title: "Patient Guide", icon: "fas fa-book-open" },
        { href: "/careers", title: "Careers", icon: "fas fa-users" },
        { href: "/contactus", title: "Contact Us", icon: "fas fa-phone" },
      ],
    };
  },
};
</script>

<style scoped></style>
