import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/careers",
    name: "Careers",
    component: () => import("../views/Careers.vue"),
  },
  {
    path: "/doctors",
    name: "Doctors",
    component: () => import("../views/Doctors.vue"),
    children: [
      {
        path: "/doctors/hmo/:hmo",
        component: () => import("../views/Doctors.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "AboutUs",
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/wellness",
    name: "Wellness Center",
    component: () => import("../views/Wellness.vue"),
  },
  {
    path: "/online-services",
    name: "Online Services",
    component: () => import("../views/OnlineServices.vue"),
    children: [
      {
        path: "/online-services/:service",
        component: () => import("../components/OnlineService.vue"),
      },
    ],
  },
  {
    path: "/clinical-departments",
    name: "Clinical Departments",
    component: () => import("../views/ClinicalDepartments.vue"),
  },
  {
    path: "/diagnostic-centers",
    name: "DiagnosticCenters",
    component: () => import("../views/DiagnosticCenters.vue"),
    children: [
      {
        path: "/diagnostic-centers/:department",
        component: () => import("../components/DiagnosticCenter.vue"),
      },
    ],
  },
  {
    path: "/uerm-community",
    name: "UermNews",
    component: () => import("../views/Community.vue"),
  },
  {
    path: "/patient-guide",
    name: "PatientGuide",
    component: () => import("../views/Patients.vue"),
    children: [
      {
        path: "/patient-guide/rooms",
        name: "GuideRooms",
        component: () => import("../components/patient-guide/Rooms.vue"),
      },
      {
        path: "/patient-guide/philhealth-hmo",
        name: "GuidePhilhealthHmo",
        component: () =>
          import("../components/patient-guide/PhilhealthHmo.vue"),
      },
      {
        path: "/patient-guide/rights",
        name: "GuidePatientRights",
        component: () =>
          import("../components/patient-guide/PatientRights.vue"),
      },
      {
        path: "/patient-guide/rules",
        name: "GuideReules",
        component: () => import("../components/patient-guide/Rules.vue"),
      },
      {
        path: "/patient-guide/shops",
        name: "GuideShops",
        component: () => import("../components/patient-guide/Shops.vue"),
      },
      {
        path: "/patient-guide/privacy-policy",
        name: "GuidePrivacyPolicy",
        component: () =>
          import("../components/patient-guide/PrivacyPolicy.vue"),
      },
    ],
  },
  {
    path: "/privacy-notice",
    name: "Privacy Notice",
    component: () => import("../views/PrivacyNotice.vue"),
  },
  {
    path: "/news-archive",
    name: "NewsArchive",
    component: () => import("../components/NewsArchive.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

export default router;
