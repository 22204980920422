<template>
  <div class="py-10 primary white--text">
    <v-container dark>
      <v-row>
        <v-col class="text-center text--white" xl="8" lg="8" sm="12" xs="12" md="8">
          <v-row>
            <v-col class="text-center" cols="6" sm="4" md="3" v-for="(nav, key) in navs" :key="key">
              <v-chip
                v-if="nav.href.substring(0,1) == 'h'"
                outlined
                dark
                text
                class="pa-2"
                target="_blank"
                :href="nav.href"
              >{{nav.title}}</v-chip>
              <v-chip
                v-if="nav.href.substring(0,1) != 'h'"
                outlined
                dark
                text
                class="pa-2"
                :to="nav.href"
              >{{nav.title}}</v-chip>
            </v-col>
            <v-col class="text-center" cols="6" sm="4" md="3">
              <v-chip
                outlined
                dark
                text
                class="pa-2"
                target="_blank"
                href="https://uerm.edu.ph"
              >Academe Website</v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="text-center text--white" xl="4" lg="4" xs="12" sm="12" md="4">
          <v-row>
            <v-col cols="12">
              <v-img
                contain
                class="white--text align-end"
                src="/img/logos/dpo-dps.png"
                height="300px"
              ></v-img>
              <v-chip
                outlined
                dark
                text
                class="pa-2"
                to="/privacy-notice"
              >Privacy Notice</v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="text-center">
      <v-row>
        <v-col cols="12" sm="4">
          <div>University of the East Ramon Magsaysay Memorial Medical Center - Since 1960</div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <a href="https://twitter.com/UERMOfficial" class="text-decoration-none" target="_blank">
              <v-icon class="mx-2" dark>fab fa-twitter</v-icon>
            </a>
            <a
              href="https://www.facebook.com/UERMOfficial"
              class="text-decoration-none"
              target="_blank"
            >
              <v-icon class="mx-2" dark>fab fa-facebook</v-icon>
            </a>
            <a
              href="https://www.instagram.com/uermofficial/"
              class="text-decoration-none"
              target="_blank"
            >
              <v-icon class="mx-2" dark>fab fa-instagram</v-icon>
            </a>
            <a
              href="https://www.youtube.com/channel/UCGpGGOFc_j8-ukBMfjGLziw?disable_polymer=true"
              class="text-decoration-none"
              target="_blank"
            >
              <v-icon class="mx-2" dark>fab fa-youtube</v-icon>
            </a>
          </div>
        </v-col>
        <v-col cols="12" sm="5">
          <div>
            <v-icon class="mx-2" dark>fas fa-map-marked-alt</v-icon>Aurora Boulevard, Quezon City, Philippines, 1113
          </div>
          <div>
            <v-icon class="mx-2" dark>fas fa-phone</v-icon>(+63 2) 8-715-0861
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navs: [
        { href: "/", title: "Home", icon: "fas fa-home" },
        { href: "/about", title: "About Us", icon: "fas fa-question-circle" },
        {
          href: "/doctors",
          title: "Meet our doctors",
          icon: "fas fa-user-md",
        },
        //  {
        //   href: "/privacy-notice",
        //   title: "Privacy Notice",
        // },
        {
          href: "/clinical-departments",
          title: "Clinical Departments",
          icon: "fas fa-stethoscope",
        },
        {
          href: "/online-services",
          title: "Online Services",
          icon: "fas fa-laptop-medical",
        },
        {
          href: "/wellness",
          title: "Wellness Center",
          icon: "fas fa-laptop-medical",
        },
        {
          href: "/diagnostic-centers/Radiology X-Ray Unit",
          title: "Diagnostic Centers",
          icon: "fas fa-stethoscope",
        },
        { href: "/careers", title: "Careers", icon: "fas fa-users" },
        { href: "/contactus", title: "Contact Us", icon: "fas fa-phone" },
        { href: "/news-archive", title: "News Archive", icon: "fas fa-phone" },
      ],
    };
  },
};
</script>

<style scoped>
</style>