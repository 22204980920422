const clinicalDepartments = [
  {
    mainImage: "https://i.imgur.com/LP0sDhLh.jpg",
    title: "Blood Bank",
    subtitle: "The power to save a life is in your hands.",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    contactno:
      "Trunkline: (+632) 8-715-0861 to 77 Loc. 235 Direct Line: (+632) 8-241-2228",
    schedule: "Monday to Sunday, 8:00 am to 5:00 pm",
    content: [
      "<h4>The Process</h4>",
      "Apheresis is the process by which we collect blood donation. It gives a higher volume of concentrated plasma or platelets for each donation from a donor. That means more plasma or platelets for patient needs. It’s almost similar to a normal blood donation except that it takes a little longer as it returns your red blood cells during donation.",
      `<h4>Who Are Eligible to Donate Blood?</h4>`,
      `<ul>
        <li>Must be in good general health (no symptoms of infection e.g. sore throat, cough, colds, allergies, fever)</li>
        <li>Weight must be at least 110 lbs / 50 kg</li>
        <li>Age must be 18 - 60 years old (17 years old must be with parental consent)</li>
        <li>Have a blood pressure of:
          <ul>
            <li>Systolic: 110-140 mmHg</li>
            <li>Diastolic: 70-90 mmHg</li>
          </ul>
        </li>
        <li>No alcohol intake for the last 24 hours</li>
        <li>No medication / antibiotic intake for the last 1 month</li>
        <li>No tattoo (must be 12 months after the procedure)</li>
        <li>2 weeks from the last day of menstruation (for female donors)</li>
      </ul>`,
      `<h4>Donation Interval</h4>`,
      `Male: 3 months, Female: 6 months`,
    ],
    catchPhrase: "Donate blood and save lives.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/ieKwZigm.jpg",
          title: "Fill Out Forms",
          content: [
            "You will fill out some forms, read about donating blood, eligibility, and donation information will be discussed.",
            `<ul>
              <li>Valid ID and List of Medications</li>
            </ul>`,
          ],
        },
        {
          image: "https://i.imgur.com/mAGRf4Fm.jpg",
          title: "Donor Screening",
          content: [
            "You will be asked about your health and travel history. Donation staff will also check your:",
            `<ul>
              <li>Body Temperature, Pulse Rate, Blood Pressure, Hemoglobin and Hematocrit</li>
              <li>Serological Screening HIV, HBSAg, RPR, HCV</li>
            </ul>`,
          ],
        },
        {
          image: "https://i.imgur.com/XHWONrRm.jpg",
          title: "Donation",
          content: [
            "A blood donation typically takes about 8 to 10 minutes.  However, Platelet Apheresis-type of donations can take up to 2 hours.",
          ],
        },
        {
          image: "https://i.imgur.com/4voax99m.jpg",
          title: "Post Donation",
          content: [
            "Have an extra glass of liquids and snack after you donate blood. After 10 to 15 minutes, you can leave.  Once you leave:",
            `<ul>
              <li>Do not drink alcohol over the next 24 hours</li>
              <li>Keep the gauze on for the next several hours</li>
              <li>Avoid heavy lifting or vigorous activities</li>
              <li>Apply a cold pack if bleeding/ bruising occurs</li>
            </ul>`,
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/lMmcFiWh.jpg",
    title: "Cardiovascular Laboratory",
    subtitle: "Heart attack can strike at any time and age!",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    contactno:
      "Trunkline: (+632) 8-715-0861 to 77 Loc. 456 or 457 Direct Line: (+632) 8-985-9349",
    schedule: "Monday to Sunday, 24 Hours",
    content: [
      "Diagnostic tests aid the health care provider to determine certain conditions of the heart. The tests can show if the heart was damaged from a heart attack and if there is the presence of coronary artery disease. They help the doctor determine the appropriate treatment necessary to keep the heart healthy and prevent major adverse cardiovascular events (heart attack, heart failure).",
      "The Cardiovascular Laboratory at UERM Medical Center offers a range of services from the basic to the more complex. The tests aim to diagnose the problem, evaluate the severity of the heart and vascular condition.",
    ],
    catchPhrase: "Live and love with a healthy heart.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/ug0Jphnm.jpg",
          title: "Carotid Duplex Scan",
          content: [
            "A carotid duplex scan is a type of ultrasound that is used to detect blockage in the carotid arteries which are located along both sides of the neck. Blocked carotid arteries are major risk factor for stroke.",
          ],
        },
        {
          image: "https://i.imgur.com/iJZXkSem.jpg",
          title: "Dobutamine Stress Test",
          content: [
            "Dobutamine stress test is a test done to assess how well the heart works under stress. The “stress” is triggered by a medicine called dobutamine.",
          ],
        },
        {
          image: "https://i.imgur.com/L7Limvem.jpg",
          title: "Ultrasound-Guided Vascular Access",
          content: [
            "Ultrasound-guided vascular access is a technique used to increase safety when performing invasive cardiovascular procedures. It also increases technical and procedural success for all invasive cardiovascular procedures.",
          ],
        },
        {
          image: "https://i.imgur.com/GYwqMCum.jpg",
          title: "Holter Monitoring",
          content: [
            "Holter monitoring is a 24-hour monitoring of the patient’s ECG. This test will detect irregularities in the heart rhythm that an ECG procedure cannot detect due to its short period duration of procedure.",
          ],
        },
        {
          image: "https://i.imgur.com/vkMkCaCm.jpg",
          title: "Stress Echo/ Treadmill",
          content: [
            "A stress echo is a test used to assess the heart's function and structures under stress. In this test, stress is triggered by exercise on a treadmill.",
          ],
        },
        {
          image: "https://i.imgur.com/zTbPLyXm.jpg",
          title: "Treadmill Stress Test",
          content: [
            "A treadmill stress test determines the patient’s risk of having heart disease. It provides information on the adequacy of blood and oxygen circulation of the heart while doing physical stress.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/1okChLQh.jpg",
    title: "CT Scan Section",
    contactno: "Trunkline: (+632) 8-715-0861 local 232",
    subtitle: "Fast. Accurate. Non-invasive.",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Sunday, 24 Hours",
    content: [
      "A Computed Tomography (CT) Scan is an advanced imaging modality that uses multiple X-Rays to reconstruct images of the inside of the body in great detail for a definite diagnosis and management planning. It is a readily available, fast, accurate, and non-invasive diagnostic procedure applicable for use in all parts of the human body.",
      `When is CT Scan recommended by doctors? <ul>
            <li>To diagnose and examine bone injuries or disorders such as fractures and bone tumors;</li>
            <li>To detect diseases like cancer, lung nodules, and liver masses as well as to guide treatment by means of biopsy;</li>
            <li>To determine extent and location of tumors, infection, or blood clots (thrombus);</li>
            <li>To monitor patient’s response to treatment such as chemotherapy; and</li>
            <li>To detect internal organ injuries, abnormal fluid collection, and presence of bleeding.</li>
          </ul>`,
    ],
    catchPhrase: "Don't take chances when you need to be at your peak.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/eUXHLNEm.jpg",
          title: "Cranial or Head CT Scan",
          content: [
            "Head CT Scan is a specialized imaging of the brain to detect the presence of cranial pathology such as, but not limited to, mass or tumor, ischemic process leading to stroke, causes of bleed or hemorrhage, and skull fracture.",
          ],
        },
        {
          image: "https://i.imgur.com/r09aDRam.jpg",
          title: "CT Stonogram",
          content: [
            "CT Stonogram is a non-contrast study that detects kidney, urethral, and urinary bladder stones, masses, and other abdominal pathologies.",
          ],
        },
        {
          image: "https://i.imgur.com/4jb8Kusm.jpg",
          title: "CT-Guided Biopsy",
          content: [
            "CT-guided biopsy is a fast, safe, and minimally invasive procedure to withdraw tissue or fluid sample from an organ or suspicious tumor using a fine needle with CT Scan guidance to determine needle position and avoid injury to adjacent solid or hollow organ.",
          ],
        },
        {
          image: "https://i.imgur.com/ifQa1zkm.png",
          title: "CT Angiogram",
          content: [
            "The computer makes a 3-D visualization of the blood vessel to detect the presence of aneurysms, thrombosis, or arteriovenous malformations.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/i5G0kAWh.jpg",
    title: "Eye Center",
    contactno: "Trunkline: (+632) 8-715-0861 local 458",
    subtitle: "Caring for the gift of sight to enjoy life's simple joys.",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Friday, 8:00 am to 5:00 pm",
    content: [
      "Aging may be associated with eyesight problems, and older patients may have a greater risk of developing eye problems and eye diseases.  However, blindness at whatever age can be avoided or treated through regular visits to an eye specialist.",
      "Regular eye exams can help prevent vision impairment.  In most cases, eye diseases in its earliest stages are detected only through an eye exam. If you have a family history of eye diseases or other risk factors (such as age, health condition, and lifestyle), then more frequent exams would be necessary.",
      `<div>
            <h3>Most Common Causes of Blindness & Visual Impairment</h3>
            <p>Cataract - Cataract is the clouding of the natural clear lens of the eye.  It develops slowly and, over time, will eventually interfere with the vision.  It is the most common cause of vision loss among elderly patients.</p>
            <p>Glaucoma - Glaucoma is a condition that causes damage to the optic nerve, a nerve that transmits information from the eye to the brain.  If left untreated, the optic nerve damage causes shrinking of the visual field that can eventually lead to blindness.</p>
            <p>Diabetic Retinopathy - Diabetic retinopathy refers to the damage in the retina in patients with diabetes mellitus.  This chronic and progressive retinal disorder causes disruption of small blood vessels and can lead to vision loss.  It is one of the leading causes of blindness worldwide.</p>
          </div>`,
      `<div>
            <h3>Comprehensive and State of the Art Diagnostic and Laser Services at UERM Medical Center</h3>
            <p>UERM Medical Center, your partner in vision care, opens its new and improved Eye Center to better serve you in diagnosing eye diseases. Our complete and best value for money eye services include: <ul>
              <li>Cataract Diagnostic Tests</li>
              <li>Glaucoma Diagnostic Tests</li>
              <li>Retina Diagnostic Tests</li>
              <li>Laser Treatments</li>
            </ul></p>
          </div>`,
    ],
    catchPhrase: "Dont't lose sight of the best things in life.",
    subImage: "",
    detailed: {
      // title: "Most Common Causes of Blindness & Visual Impairment",
      cards: [
        {
          image: "https://i.imgur.com/Ti91WUEm.jpg",
          title: "Optical Biometry, Specular Microscopy and AR-Keratometry",
          content: [""],
        },
        {
          image: "https://i.imgur.com/UqTTqvkm.jpg",
          title: "Visual Field Exam",
          content: [""],
        },
        {
          image: "https://i.imgur.com/mgwcsoWm.jpg",
          title: "Optical Coherence Tomography (OCT)",
          content: [""],
        },
        {
          image: "https://i.imgur.com/GE40lfum.jpg",
          title: "A-Scan and B-Scan",
          content: [""],
        },
        {
          image: "https://i.imgur.com/h2TUcxBm.jpg",
          title: "Fluorescein Angiography",
          content: [""],
        },
        {
          image: "https://i.imgur.com/Ky7XaR9m.jpg",
          title: "Pascal Laser Panretinal Photocoagulation (PRP)",
          content: [""],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/BzgY0pJh.jpg",
    title: "General Ultrasound Section",
    contactno: "Trunkline: (+632) 8-715-0861 local 338",
    subtitle: "A look from the outside in when certainty is vital.",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Saturday, 8:00 am to 5:00 pm",
    content: [
      "Ultrasound imaging or sonography is a non-invasive diagnostic technique used to image the inside of the body for the following purposes:",
      `<ul>
            <li>For investigation and helping find the causes of pain and determining the nature of palpable masses that may denote infection, presence of benign or malignant tumors, obstructing stones and soft tissue/tendon abnormalities.</li>
            <li>For volume determination of fluid in the chest and abdominal cavity.</li>
            <li>For evaluation of neonatal brain for presence of bleed, hydrocephalus, or infection.</li>
            <li>For guidance during biopsies and centesis that require precise and safe techniques.</li>
            <li>For Focus Assessment Sonography for Trauma (FAST).</li>
          </ul>`,
      "Ultrasound is a safe imaging procedure that does not use radiation. Images are captured in real-time and, as such, can differentiate the normal from the abnormal structures and relationship to adjacent organs. Blood flowing through the blood vessels is also demonstrable in the images.",
      `<div>
            <h3>What other special ultrasound procedures are available?</h3>
            <ul>
              <li>Musculoskeletal studies for soft tissue, muscular, and tendon pathologies.</li>
              <li>Minimally invasive guided procedures such as:
                <ul>
                  <li>Guided biopsy of masses</li>
                  <li>Thoracentesis</li>
                  <li>Paracentesis</li>
                  <li>Pig-tail insertion</li>
                  <li>Aspiration and drainage of cystic masses and abscess.</li>
                </ul>
              </li>
              <li>Non-invasive assessment of hepatic parenchyma using liver elastography, which indirectly measures liver fibrosis.</li>
            </ul>
          </div>`,
    ],
    catchPhrase: "Leave no doubt. Take no chances.",
    subImage: "",
    detailed: {
      cards: [
        {
          contain: true,
          image: "https://i.imgur.com/Dj6BoCBm.png",
          title: "Whole Abdomen Ultrasound",
          content: [
            "Whole Abdomen Ultrasound is a complete sonographic study of the abdominal structures which include the liver, pancreas, spleen, gall bladder, kidneys, urinary bladder, prostate, uterus, and ovaries. An ultrasound is also a cost-effective examination for an abnormal appendix.",
          ],
        },
        {
          contain: true,
          image: "https://i.imgur.com/gs51pxQm.png",
          title: "Thyroid and Neck Ultrasound",
          content: [
            "Ultrasound of small parts using high-frequency transducers are very sensitive in evaluating the thyroid and neck structures. Non-palpable nodules can be detected easily using the high-powered probes.",
          ],
        },
        {
          contain: true,
          image: "https://i.imgur.com/LDV6UGTm.jpg",
          title: "Liver Ultrasound",
          content: [
            "An ultrasound is usually the first imaging modality requested for assessment of liver pathology which includes fatty liver, cirrhosis, hepatitis, and possible masses. Aside from the routine technique of liver scanning, the high-end machine available in our institution is also capable of hepatic elastography.",
          ],
        },
        {
          contain: true,
          image: "https://i.imgur.com/LueOOxwm.png",
          title: "Ultrasound-Guided Nephrostomy Tube Insertion Procedure",
          content: [
            "This is an ultrasound-guided insertion of a tube within an obstructed passage of urine to relieve or prevent further kidney damage.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/Vu6qC1Yh.jpg",
    title: "Gastrointestinal - Liver Study Unit",
    subtitle: "Wishing for more birthdays beyond 50!",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Saturday, 7:00 am to 5:00 pm",
    contactno: "Trunkline: (+632) 8-715-0861 local 345",
    content: [
      "At age 50, colon cancer screening should be an important part of our regular health care. Having no symptoms, history, or signs of the disease do not give guarantee of the absence of colon cancer. We should set aside our fear of colon cancer screening, as any discomfort in the procedure is only temporary.",
      "Colon cancer is now the 3rd leading type of cancer in the Philippines (Globocan, 2018).  It is usually curable, for as long as it is detected in the early stages. The incidence and mortality rate of colorectal cancer can be reduced through early detection.",
    ],
    catchPhrase: "Early detection for more birthday celebrations!",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/eSNwgIJh.jpg",
          title: "Colonoscopy",
          content: [
            "Colonoscopy allows visualization of the entire large intestine and detection of polyps.  It is the most cost-effective method for colorectal cancer prevention through polyp removal.",
          ],
        },
        {
          image: "https://i.imgur.com/ebzDMEXh.jpg",
          title: "ERCP (Endoscopic Retrograde Cholangio-Pancreatography)",
          content: [
            "ERCP allows the visualization and treatment of the bile duct and pancreatic duct diseases.  It is a cost-effective method of removal of common bile duct stones.",
          ],
        },
        {
          image: "https://i.imgur.com/MyyK1ihh.jpg",
          title: "Gastroscopy",
          content: [
            "Gastroscopy allows visualization of the esophagus, stomach, and a portion of the small intestine. It can detect illnesses such as dysphagia and gastroesophageal reflux disease (GERD).",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/S0aOBIch.jpg",
    title: "Hearing & ENT Diagnostic Center (ENT)",
    subtitle: "A simple discomfort may not be simple as it seems!",
    contactno: "Trunkline: (+632) 8-715-0861 local 299",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Saturday, 8:00 am to 4:00 pm",
    content: [
      "Head and Neck Endoscopy is the insertion of a short, rigid or flexible instrument into the nose to visualize the nasal cavity, and nasopharynx or through the mouth to visualize the hypopharynx, and the areas above the larynx and esophagus.",
      `The modern and minimally invasive Head and Neck Endoscopy at UERM ENT Diagnostic Center has the following purposes: <ul>
            <li>Early detection and diagnosis of patients with problems in the nose, nasopharynx, hypopharynx, and larynx.</li>
            <li>Minor surgical procedures, like removal of foreign bodies, biopsies of lesions in the nose, nasopharynx, and oral cavity, myringotomy for fluid in the middle ear.</li>
            <li>Documentation of patients pre-operatively and post-operatively.</li>
          </ul>`,
    ],
    catchPhrase: "Don't take chances when you need to be at your peak.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/uvN2dedm.jpg",
          title: "Fiberoptic Evaluation of Swallowing",
          content: [
            "Adapted to swallowing problems associated with the pediatric and adult age group.",
          ],
        },
        {
          image: "https://i.imgur.com/HD7gx4gm.jpg",
          title: "Rigid Laryngoscopy",
          content: [
            "Uses a 70-degree endoscope to look into the laryngeal area, evaluating the status of the laryngeal structures and vocal fold mobility.",
          ],
        },
        {
          image: "https://i.imgur.com/6bFwiA8m.jpg",
          title: "Rigid Nasal Endoscopy",
          content: [
            "A procedure to look at the nasal passages and sinus area openings and the areas of the nasopharynx through the scope as it is projected onto the screen.",
          ],
        },
        {
          image: "https://i.imgur.com/qqLyPREm.jpg",
          title: "Flexible Naso-pharyngolaryngoscopy",
          content: [
            "A diagnostic medical procedure that uses a flexible fiberoptic endoscope to visualize the structures inside the nasal passages, including the sinus openings, the larynx, and the vocal cords.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/A3m0BSih.jpg",
    title: "Hearing & ENT Diagnostic Center (Hearing)",
    subtitle: "Don't be the last one to know!",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    contactno: "0927 430 8004",
    schedule: "Monday to Saturday, 8:00 am to 4:00 pm",
    content: [
      "The problem is not the hearing loss itself, but the inability to recognize the signs and symptoms as early as possible. It can strike at any age from newborn to old age.  Left unattended, hearing loss can lead to all sorts of feelings from isolation to loneliness.",
    ],
    catchPhrase: "Break free. Live life without limitations!",
    subImage: "",
    detailed: {
      // title:
      //   "Quality Sleep Study Procedures... no long queues and scheduling... made more affordable at UERM Medical Center!",
      cards: [
        {
          image: "https://i.imgur.com/i6x6K3sm.jpg",
          title: "Otoacoustic Emission (OAE)",
          content: [
            "This is one of the required screening tests for newborns. It determines if the receptors (Outer Hair Cells) of sound in the inner ear are functional.",
            "This test is required in the Comprehensive Newborn Screening Program (Republic Act No. 9288, otherwise known as Newborn Screening Act of 2004).",
          ],
        },
        {
          image: "https://i.imgur.com/dooDWsEm.jpg",
          title: "Tympanometry with Acoustic Reflex Threshold (ART)",
          content: [
            "Tympanometry is a test used to evaluate the status of the eardrum and the middle ear space. A stable tone and changing pressure are introduced to the ear canal by a sealed probe.",
            "Acoustic Reflex Threshold is a physiologic test that determines the status of the middle ear, differentiates cochlear vs retro-cochlear lesions, estimates degree of peripheral hearing sensitivity and can be used to validate results of Puretone Audiometry. ",
          ],
        },
        {
          image: "https://i.imgur.com/ui1CsqJm.jpg",
          title: "Pure Tone Audiometry (PTA) with Speech Testing (PTA-ST)",
          content: [
            "Pure Tone Audiometry is a routine non-invasive hearing test that determines the type and degree of hearing loss. The patient listens and confirms hearing sounds at different frequencies and intensities using a headphone and, if necessary, a bone oscillator.",
            "Speech Testing aids in measuring the hearing loss in conjunction with Pure Tone Audiometry. It also provides information regarding discomfort or tolerance to speech stimuli and information on word recognition abilities. This is done by asking patients to confirm if they hear a tone and to repeat simple words either in Filipino or English.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/AtajXDkh.jpg",
    title: "Hemodialysis",
    subtitle: "Failed or damaged kidneys is a serious condition.",
    contactno: "Trunkline: (+632) 8-715-0861 local 348",
    location: "4 East Wing, Hospital Building, UERM Medical Center",
    schedule: "Monday to Saturday, 7:00 am to 11:00 pm",
    content: [
      "Hemodialysis is one best means to treat progressive kidney failure and to sustain the patient in living an active life in spite of deteriorating kidneys.",
      "Kidneys function as regulators of the body’s levels of water and minerals and removal of waste. Thus, when people have failed or damaged kidneys, eliminating waste and unwanted water from the blood becomes difficult. Through dialysis, it is able to carry out this process in an artificial way.",
    ],
    catchPhrase:
      "Don't wait for the waste products in the blood to reach hazardous levels!",
    subImage: "",
    detailed: {
      // cards: [
      //   {
      //     image: "https://i.imgur.com/flDs76em.jpg",
      //     title: "Low Flux Dialysis",
      //     content: [
      //       "The machines filter the fluids, wastes, or salts from the patient’s blood when the kidneys are no longer healthy enough to do this work adequately.",
      //     ],
      //   },
      //   {
      //     image: "https://i.imgur.com/VVVhrQom.jpg",
      //     title: "High Flux Dialysis",
      //     content: [
      //       "Dialyzers or artificial kidneys with larger pores are used to remove both uremic toxins and fluid.",
      //     ],
      //   },
      // ],
    },
  },
  {
    mainImage: "https://imgur.com/6mRTW2dh.jpg",
    title: "Mammography",
    contactno: "Trunkline: (+632) 8-715-0861 local 367",
    subtitle: "Nothing to lose and everything to gain!",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Saturday, 8:00 am to 5:00 pm",
    content: [
      "The risk of breast cancer increases with age thus making it important for menopausal women to get regular mammograms. Some doctors recommend starting earlier than age 40. This is a personal decision between the patient and the doctor depending on individual risk factors.",
      "Early detection of the signs of breast cancer may just save you your life. Screening mammography can detect signs of breast cancer as early as three years before it can be felt.  While it cannot prevent cancer, mammography can help aid in preventing the spread and reducing death from breast cancer. There is better chance of treatment and recovery when breast cancer is detected early.",
    ],
    catchPhrase: "Better safe than sorry.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://imgur.com/mRIqJvMm.jpg",
          title: "Mammography",
          content: [
            "It is used to detect and diagnose breast disease in women who either have breast problems, such as a lump, pain, or nipple discharge, as well as for women who have no breast complaints. The procedure allows detection of breast cancers and benign tumors before they can be detected by palpation (touch).",
          ],
        },
        {
          image: "https://imgur.com/pM7i1xAm.jpg",
          title: "Needle Wire Optimization",
          content: [
            "This type of procedure is performed when you have an abnormality seen on a mammogram that cannot be felt. The purpose of this procedure is to help identify the precise location of abnormal breast tissue. This is done by placing a small wire at the point of the abnormality.",
          ],
        },
        {
          image: "https://imgur.com/jEILnrBm.jpg",
          title: "Breast Care Package (Breast Ultrasound + Mammography)",
          content: [
            "The breast ultrasound can help examine and characterize the lump or mass seen in Mammography. It is an imaging technique commonly used to screen for tumors and other breast abnormalities. This uses high-frequency sound waves to produce detailed images of the inside of the breasts.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/jouZNMah.jpg",
    title: "Neurophysiology Laboratory",
    subtitle: "Getting Through Seizures",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Saturday, 8:00 am to 5:00 pm",
    contactno:
      "Trunkline: (+632) 8-715-0861 local 386 Direct Line: (+632) 8-715-8405",
    content: [
      "The Neurophysiology Laboratory at UERM Medical Center offers comprehensive diagnostic services and laboratories delivered by a collaborative team of physicians and health care providers with expertise in neurological and neuromuscular disease.",
      `The skilled technologists and electroencephalographers conduct and interpret a range of neurodiagnostic tests that help physicians in diagnosing diseases of the brain, nervous system, and muscles such as epilepsy, disseminated sclerosis, neuritis, nerve damage, and muscular atrophy.`,
    ],
    catchPhrase: "For better tomorrows.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/zP6AwUNm.jpg",
          title: "Electroencephalography (EEG)",
          content: [
            "An EEG is a diagnostic test to assess the electrical activity of the brain. It is one of the main diagnostic tests used for the assessment of seizures and epilepsy. It may provide additional information for other neurologic disorders such as dementia, brain tumors, head trauma, CNS infections, and stroke. It is also valuable in the assessment of patients presenting with decreased or altered levels of sensorium, change in behavior, and loss of consciousness among others.",
            `<ul>
              <li>Routine EEG</li>
              <li>Sleep-Deprived EEG</li>
              <li>Prolonged EEG Recording</li>
            </ul>`,
          ],
        },
        {
          image: "https://i.imgur.com/ZHdJ32em.jpg",
          title: "Electromyography and Nerve Conduction Studies (EMG-NCV)",
          content: [
            "EMG-NCV is a diagnostic procedure to assess muscles and nerves. EMG results may aid in the assessment of muscle and nerve dysfunction or problems with nerve-to-muscle signal transmission. EMG-NCV studies may be useful in the assessment of patients presenting with numbness, tingling sensations, muscle weakness, muscle pain/cramping and limb pain. This procedure is essential in the diagnosis of muscle disorders (e.g. muscular dystrophies or polymyositis), diseases affecting the neuromuscular junction (e.g. myasthenia gravis), neuropathies (e.g. carpal tunnel syndrome, diabetic peripheral neuropathies), motor neuron disease (e.g. amyotrophic lateral sclerosis) and disorders affecting the nerve roots.",
            `<ul>
              <li>EMG-NCV (2 Extremities / 4 Extremities)</li>
              <li>Repetitive Nerve Stimulation Test (RNS)</li>
              <li>Single Fiber EMG-NCV and Blink Studies</li>
            </ul>`,
          ],
        },
        {
          image: "https://i.imgur.com/yru8T54m.jpg",
          title: "Evoked Potentials (EPs)",
          content: [
            "Evoked potentials (EPs) measure the electrophysiologic responses of the nervous system to a variety of stimuli. They help assess the functionality and supply important information about impairments of electrical conduction along a specific sensory pathway. They may be used in the assessment of specific diseases such as multiple sclerosis and help in prognostication in cases of traumatic and anoxic brain injury.",
            `<ul>
              <li>Visual Evoked Potential (VEP)</li>
              <li>Somatosensory Evoked Potential (SSEP)</li>
              <li>Brainstem Auditory Evoked Potential (BAEP)</li>
            </ul>`,
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/0vV02n5h.jpg",
    title: "Obstetric & Gynecologic Ultrasound",
    subtitle: "We monitor your journey...",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Saturday, 8:00 am to 5:00 pm",
    contactno: "Trunkline: (+632) 8-715-0861 local 338",
    content: [
      "Ultrasound is an imaging tool which uses high-frequency sound waves to produce an image of the fetus and placenta as well as the female reproductive organs on a monitor. It is an indispensable diagnostic tool in the practice of Obstetrics and Gynecology.",
      "In Obstetrics, ultrasound is a safe, painless, and non-invasive tool essential in dating and confirmation of normal and abnormal pregnancies, diagnosis of multiple pregnancy and congenital anomaly as well as monitoring of fetal growth and well-being.",
      "In Gynecology, ultrasound is helpful in the work-up of patients with abnormal menstruation or vaginal bleeding, infertility, pelvic infection, abdominal pain, and abdominal tumor-like myoma and cysts.",
    ],
    catchPhrase: "… so you can focus on what’s ahead. Enjoy motherhood!",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/ct3pAsYm.jpg",
          title: "Transvaginal / Transrectal Ultrasound",
          content: [
            "This is carried out by inserting an endovaginal probe to gain better visualization of the uterus, cervix, endometrium, ovaries, and other pelvic structures.",
          ],
        },
        {
          image: "https://i.imgur.com/bDrhZYfm.jpg",
          title: "Transabdominal Ultrasound",
          content: [
            "Assessment of the fetus beyond the first trimester of pregnancy is done through an abdominal ultrasound.  It gives information about the fetal growth, presence of congenital abnormality, fetal well being, placental location and maturity, and the amount of amniotic fluid.  In non-pregnant women with abdominal tumor, it complements a transvaginal ultrasound to better assess the origin and nature of the tumor.",
          ],
        },
        {
          image: "https://i.imgur.com/mY8lu8tm.jpg",
          title: "Biophysical Profile Score (BPS)",
          content: [
            "This is done during the second and third trimesters of pregnancy to assess fetal well being and adequacy of oxygen and nutrient supply from the mother by observing certain fetal activities like body movement, breathing, tone, and the amount of amniotic fluid.",
          ],
        },
        {
          image: "https://i.imgur.com/14WAslhm.jpg",
          title: "3D/4D Ultrasound",
          content: [
            "In a 3D ultrasound, a three-dimensional image of the fetus can be seen. While the 4D ultrasound reproduces a moving image of the fetus inside the womb. The 4D ultrasound uses sound waves to create this moving image. A 4D ultrasound takes this experience to the next level. It creates an effect that is similar to watching a live video.",
          ],
        },
        {
          image: "https://i.imgur.com/PbziZhBh.jpg",
          title: "Doppler Ultrasound",
          content: [
            "A Doppler ultrasound is a procedure that measures the amount of blood flow through the arteries and veins, usually those that supply blood to the arms and legs.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/E91wVY6h.jpg",
    title: "Pathology Laboratory",
    subtitle: "Tried, tested, and trusted.",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Sunday, 24 hours",
    contactno:
      "Trunkline: (+632) 8-715-0861 local 236 Direct Line: (+632) 8-713-7271",
    content: [
      "The Pathology Laboratory of UERM Medical Center has served the medical community for over sixty years.",
      "It has continuously carried out quality tests on clinical specimens; obtaining fast and accurate information to help diagnose, treat, and prevent disease.",
    ],
    catchPhrase: "Quality service through the years.",
    subImage: "",
    detailed: {
      // cards: [
      //   {
      //     image: "https://i.imgur.com/aHmmwe2m.jpg",
      //     title: "CBC Complete Blood Count with Platelet Count",
      //     content: [
      //       "This test is the primary screening tool to determine if there is an underlying systemic disease such as infection and bleeding, among others.",
      //     ],
      //   },
      //   {
      //     image: "https://i.imgur.com/sJ0sxrhm.jpg",
      //     title: "Culture & Sensitivity Test",
      //     content: [
      //       "This test is the primary screening tool to determine if there is an underlying systemic disease such as infection and bleeding, among others.",
      //     ],
      //   },
      //   {
      //     image: "https://i.imgur.com/qCOJZGbm.jpg",
      //     title: "Lipid Profile (TC, TRIG, HDL, LDL)",
      //     content: [
      //       "This is a microbiologic test that can be used for urine, sputum, wounds, and other fluids in the body to determine the organism that causes infection and determine the appropriate antibiotic treatment.",
      //     ],
      //   },
      // ],
    },
  },
  {
    mainImage: "https://i.imgur.com/wHYU3Bhh.jpg",
    title: "Physical Medicine and Rehabilitation",
    subtitle: "Functionality & Empowerment",
    contactno: "Trunkline: (+632) 8-715-0861 local 367",
    location: "PMR Clinic, G/F, PBL Building, North Campus, UERMMMCI",
    schedule: "Monday to Saturday, 8:00 am to 5:00 pm",
    content: [
      "Rehabilitation Medicine, also called Physiatry, is the Medical Specialty that is dedicated to restoring optimal function to people with temporary or permanent disabilities and injuries.  These may range from minor impairments to complex conditions such as:",
      "FUNCTIONAbiLITY : myofascial pain; sports injury; carpal tunnel syndrome; spinal cord injury; stroke; cardiopulmonary impairment; Bell's palsy, arthritis",
      "EMPOWERdevelopMENT :  prostheses for amputation & limb loss;  orthoses for weakness, paralysis, deformity, pain, arthritis; developmental delay",
      "Physiatrists, the medical specialists that practice Rehabilitation Medicine, work closely with licensed allied medical staff in restoring or maximizing the functional skills, self-sufficiency, and mobility of patients.",
    ],
    catchPhrase: "Restore your quality of life.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/sWnRirMm.png",
          title: "Physical Therapy",
          content: [
            "The art and science of using physical modalities such as heat, cold, soundwaves, electrical stimulation; and therapeutic exercises to relieve pain, enhance mobility, and preserve body function.",
          ],
        },
        {
          image: "https://i.imgur.com/Qv322ecm.jpg",
          title: "Occupational Therapy",
          content: [
            "The art and science of using purposeful activity, including its interpersonal and environmental components, to mediate and prevent dysfunction and to elicit maximal adaptation in performance of activities of daily living.",
          ],
        },
        {
          image: "https://i.imgur.com/zHKaFWGm.jpg",
          title: "Prosthetics and Orthotics",
          content: [
            "The art and science of assessment, prescription, fabrication, fitting & training of  prostheses (artificial limbs) and orthoses (braces) to achieve optimum and safe mobility.",
          ],
        },
        {
          image: "https://i.imgur.com/CDXLCSFm.jpg",
          title: "Speech Therapy",
          content: [
            "The art and science of evaluation and management of communication, voice, and swallowing disorders.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/GisGAezh.jpg",
    title: "Pulmonary Laboratory",
    subtitle: "Your life is worth living… your lungs worth saving!",
    contactno: "Trunkline: (+632) 8-715-0861 local 382",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Sunday, 24 hours",
    content: [
      "UERM Medical Center’s Pulmonary Laboratory offers services for patients with illnesses affecting the lungs and breathing. It supports ideal patient care for both diagnostic and therapeutic interventions both in the in-patient or out-patient setting.",
      "Therapeutic spirometry is also available at the center’s Pulmonary Laboratory. Therapeutic spirometry assesses and describes the gravity of the expiration of air from the lungs.",
      `The Pulmonary Laboratory supports pulmonary function and testing for all lung diseases such as: <ul>
        <li>Acute respiratory distress syndrome (ARDS)</li>
        <li>Asthma</li>
        <li>Chronic obstructive pulmonary disease (COPD)</li>
      </ul>`,
    ],
    catchPhrase: "Breathe better. Feel Better.",
    subImage: "",
    detailed: {
      cards: [
        {
          image: "https://i.imgur.com/1yuN3iBm.jpg",
          title: "Arterial Blood Gas Analysis",
          content: [
            "It is one of the tests ordered to assess respiratory status because it helps evaluate gas exchange in the lungs.",
          ],
        },
        {
          image: "https://i.imgur.com/LgJ1Hb8m.jpg",
          title: "Pulmonary Function Test",
          content: [
            "This is a basic test that measures lung volumes and capacities. It is a useful diagnostic tool in assessing patients with pulmonary disabilities.",
          ],
        },
        {
          image: "https://i.imgur.com/SlpWNzAm.jpg",
          title: "Aerosol Therapy (Nebulization)",
          content: [
            "It is an established component of respiratory care, it aids bronchial hygiene by restoring and maintaining mucous blanket continuity, hydrating dried retained secretions; promoting expectoration of secretions; humidifying inspired oxygen and delivering medications.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://i.imgur.com/wQpgO2uh.jpg",
    title: "Radiology X-Ray Section",
    contactno: "Trunkline: (+632) 8-715-0861 local 238",
    subtitle: "Diagnosing patient's condition through X-Ray vision.",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    schedule: "Monday to Sunday, 24 hours",
    content: [
      "Radiography, better known as X-Ray, is a fast and non-invasive imaging modality that helps doctors in diagnosing a patient’s condition. In addition, tissue and organ details not visualized in a plain X-Ray can be delineable in special contrast procedures through the use of oral or injectable contrast solution.",
      "Because symptoms could sometimes point to more than one problem, X-Ray imaging becomes a necessity in investigating what is possibly happening inside the human body without the need for invasive procedures, i.e., surgery. Correlated radiographic and clinical diagnoses will serve as basis for appropriate treatment that will lead to faster patient recovery.",
      `X-Ray is useful in: <ul>
            <li>Diagnosing bone and joint-related conditions such as fractures and dislocations;</li>
            <li>Baseline study for non-specific signs and symptoms of pain, discomfort, bloatedness, etc.;</li>
            <li>Determining the commonly encountered heart and lung problems such as pneumonia, emphysema, lung masses, pulmonary congestion/edema, pleural effusion, and enlarged heart;</li>
            <li>Monitoring progression of diagnosed diseases such as pulmonary tuberculosis, pneumonia, intestinal obstruction and fractures; and</li>
            <li>Ruling out life-threatening conditions related to the lungs, heart, and intestines.</li>
          </ul>`,
    ],
    catchPhrase: "For a better view of your condition.",
    subImage: "https://i.imgur.com/zEo5Cavh.jpg",
    detailed: {
      // title:
      //   "Quality Sleep Study Procedures... no long queues and scheduling... made more affordable at UERM Medical Center!",
      cards: [
        {
          image: "https://i.imgur.com/MWbXinYm.jpg",
          title: "Chest X-Ray",
          content: [
            "Chest X-Ray is the most common radiographic procedure performed not only to investigate all chest-related complaints, but also to serve as a baseline study for different health conditions.",
          ],
        },
        {
          image: "https://i.imgur.com/f8K9p3Am.jpg",
          title: "Barium Enema X-Ray",
          content: [
            "Barium enema X-Ray, or lower gastrointestinal (GI) tract radiography, is a special procedure to examine the large intestine or colon using a liquid suspension, i.e., barium, to highlight the different areas of the large intestine.",
          ],
        },
        {
          image: "https://i.imgur.com/KUuaDAgm.jpg",
          title: "Esophagogram / Barium Swallow X-Ray",
          content: [
            "This is a special contrast procedure that makes use of a liquid suspension, i.e., barium, to examine the upper gastrointestinal (GI) tract, specifically, the pharynx and esophagus.",
          ],
        },
        {
          image: "https://i.imgur.com/HAUE4j4m.png",
          title: "Fistulogram X-Ray",
          content: [
            "This is a special procedure using a contrast material, i.e., dye, to examine a fistula or abnormal passage/connection between two or more organs.",
          ],
        },
      ],
    },
  },
  {
    mainImage: "https://imgur.com/20Soi2Ch.jpg",
    title: "Sleep Laboratory",
    subtitle: "Don't get caught sleeping on the job!",
    location: "2nd Floor, Hospital Building, UERM Medical Center",
    contactno: "0927 430 8004",
    schedule:
      "Monday to Friday, 8:00 am to 5:00 pm (Sleep study hours: 6:00 pm to 6:00 am)",
    content: [
      "Sleep is both a physiological and behavioral process that an individual requires to carry out his daily function. It is a basic human need that is essential for the normal functioning of the body’s metabolism, immune system and cognitive functions.",
      "Sleeping disorders may affect your performance of daily routine. A simple task like driving or operating a machine can put your life at risk.",
    ],
    catchPhrase: "Re-gain back your energy and be the best at what you do.",
    subImage: "",
    detailed: {
      // title:
      //   "Quality Sleep Study Procedures... no long queues and scheduling... made more affordable at UERM Medical Center!",
      cards: [
        {
          image: "https://imgur.com/luwmmtwm.jpg",
          title: "Diagnostic Sleep Study",
          content: [
            "A diagnostic polysomnography sleep study is a procedure done to investigate the underlying cause of sleep disorder. This study is most commonly conducted overnight and is normally the first study completed at the sleep laboratory.",
          ],
        },
        {
          image: "https://imgur.com/7JUzceCm.jpg",
          title: "Therapeutic Sleep Study",
          content: [
            "A therapeutic sleep study is a polysomnography sleep study done to investigate the effectiveness of a therapy on the quality of your sleep. This study is performed after the diagnostic study and a consultation with a Sleep Specialist.",
          ],
        },
        {
          image: "https://imgur.com/gY0szW7m.jpg",
          title:
            "Multiple Sleep Latency Test (MSLT) / Maintenance of Wakefullness Test (MWT)",
          content: [
            "These studies are requested by your Sleep Specialist in the diagnosis of certain sleep disorders or the determination of excessive daytime sleepiness (EDS).",
          ],
        },
        {
          image: "https://i.imgur.com/3qE21URm.jpg",
          title: "Split-Night Sleep Study",
          content: [
            "This study is performed when a patient is suspected to have severe obstructive sleep apnea. The study starts with a two-hour period of baseline diagnostic sleep study recording, followed by a Continuous Positive Airway Pressure (CPAP) titration study.",
          ],
        },
      ],
    },
  },
];

module.exports = clinicalDepartments;
